import Cookies from "js-cookie";
import axios from "axios";

const token = () => Cookies.get("accessTokenOfficeOlin");

export const getPoList = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}purchase-order/get`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getPoListCW = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}purchase-order/get`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const createNewPo = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}purchase-order/create`,
      method: "POST",
      data: body,
    };
    await axios(config);
  } catch (err) {
    throw err;
  }
};

export const createNewPoV2 = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}purchase-order/create-new`,
      method: "POST",
      data: body,
    };
    await axios(config);
  } catch (err) {
    throw err;
  }
};

export const getPoProducts = async (limit, offset, search, warehouse) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${
        process.env.REACT_APP_API_PRODUCT
      }purchase-order/get-product?limit=${limit}&offset=${offset}&keyword=${search}&warehouse=${
        warehouse ? warehouse : false
      }`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getPoDetail = async (id, isPartial, isBilling, warehouse) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${
        process.env.REACT_APP_API_PRODUCT
      }purchase-order/get-detail?po_id=${id}&is_billing=${isBilling}&is_partial=${isPartial}&warehouse=${
        warehouse ? warehouse : false
      }`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getPdfPo = async (id, warehouse) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${
        process.env.REACT_APP_API_PRODUCT
      }purchase-order/print-pdf?po_id=${id}&warehouse=${
        warehouse ? warehouse : false
      }`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const updatePo = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "PUT",
      data: body,
      url: `${process.env.REACT_APP_API_PRODUCT}purchase-order/update`,
    };
    await axios(config);
  } catch (err) {
    throw err;
  }
};

export const getCsvBilling = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}purchase-order/billing-print`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const exportCsvBilling = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}purchase-order/billing-print-selected`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const deleteProductPO = async (id, warehouse) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${
        process.env.REACT_APP_API_PRODUCT
      }purchase-order/delete-product?id=${id}&warehouse=${
        warehouse ? warehouse : false
      }`,
      method: "DELETE",
    };

    const res = await axios(config);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getTemplatePoExcel = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}product/csv/get-template-po`,
      method: "GET",
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const createPoExcel = async (file) => {
  const formData = new FormData();
  formData.append("excel_data", file);

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}product/create-po-excel`,
      method: "POST",
      data: formData,
    };

    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const getDistributorList = async (limit, offset, search) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}purchase-order/get-distributor?limit=${limit}&offset=${offset}&search=${search}`,
      method: "GET",
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getDistributorListV2 = async (limit, offset, search) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}purchase-order/get-distributor-new?limit=${limit}&offset=${offset}&search=${search}`,
      method: "GET",
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getDistributorListV2All = async (search) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}purchase-order/get-distributor-new?search=${search}`,
      method: "GET",
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const setNewDistributor = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}purchase-order/distributor`,
      method: "POST",
      data: body,
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const updateDistributor = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}purchase-order/update-distributor`,
      method: "POST",
      data: body,
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const deleteDistributor = async (id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}purchase-order/delete-distributor?id=${id}`,
      method: "DELETE",
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const deletePurchaseOrder = async (po_id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}purchase-order?po_id=${po_id}`,
      method: "DELETE",
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};
